var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mew-stepper", {
    attrs: { items: _vm.stepperItems, "on-step": _vm.step },
    scopedSlots: _vm._u(
      [
        _vm.step === 1
          ? {
              key: "stepperContent1",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    { staticClass: "align-start justify-space-between" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "subtitle-1 font-weight-bold grey--text",
                            },
                            [_vm._v("STEP 1.")]
                          ),
                          _c(
                            "div",
                            { staticClass: "headline font-weight-bold" },
                            [_vm._v("Select your Keystore File")]
                          ),
                          _c("p", { staticClass: "mb-3 mb-sm-10 mb-md-5" }, [
                            _vm._v(
                              " Please select keystore file that unlocks your wallet. "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-center d-sm-none",
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "75%" },
                                attrs: {
                                  src: require("@/assets/images/icons/keystore-file.jpg"),
                                  alt: "Keystore File",
                                },
                              }),
                            ]
                          ),
                          _c("mew-button", {
                            attrs: {
                              title: "Select File",
                              "btn-style": "outline",
                              "color-theme": "primary",
                              "btn-size": "xlarge",
                              "has-full-width": true,
                              "shows-active-state": true,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.uploadBtn.apply(null, arguments)
                              },
                            },
                          }),
                          _c("input", {
                            ref: "jsonInput",
                            staticStyle: { display: "none" },
                            attrs: { type: "file", name: "file" },
                            on: { change: _vm.uploadFile },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: { cols: "12", sm: "6" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-start justify-sm-end",
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "75%" },
                                attrs: {
                                  src: require("@/assets/images/icons/keystore-file.jpg"),
                                  alt: "Keystore File",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        _vm.step === 2
          ? {
              key: "stepperContent2",
              fn: function () {
                return [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "border-radius--10px pa-4 pa-md-12",
                      attrs: { color: "white" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "subtitle-1 font-weight-bold grey--text",
                        },
                        [_vm._v("STEP 2.")]
                      ),
                      _c("div", { staticClass: "headline font-weight-bold" }, [
                        _vm._v("Enter Password"),
                      ]),
                      _c("div", { staticClass: "mb-5" }, [
                        _vm._v("Enter your password to unlock your wallet."),
                      ]),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.unlockBtn.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "align-stretch" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("mew-input", {
                                    ref: "passwordInput",
                                    attrs: {
                                      label: "Enter Password",
                                      placeholder: "Enter my keystore password",
                                      type: "password",
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pt-0", attrs: { cols: "12" } },
                                [
                                  !_vm.isUnlockingKeystore
                                    ? _c("app-btn-row", {
                                        staticClass: "pb-5",
                                        attrs: {
                                          "next-btn-text": "Access Wallet",
                                          "next-btn-method": _vm.unlockBtn,
                                          "back-btn-method": _vm.backStepOne,
                                          "next-disable": !_vm.password,
                                        },
                                      })
                                    : _c(
                                        "v-row",
                                        {
                                          staticClass: "pt-5 pb-9",
                                          attrs: {
                                            justify: "center",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "greenPrimary",
                                            },
                                          }),
                                          _c(
                                            "p",
                                            { staticClass: "mb-0 mx-3" },
                                            [
                                              _vm._v(
                                                " Sit tight while we are unlocking your wallet "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }